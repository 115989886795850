body {
  background-color: $body-color;
  overflow-x: hidden;
}

::selection {
  background: $primary-color;
  color: $white;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $body-color;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a:hover {
  color: $primary-color;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-title {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  &::before {
    position: absolute;
    height: 4px;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.border-default{
  border-color: $border-color !important;
}

.border-primary{
  border-color: $primary-color !important;
}

.font-primary{
  font-family: $primary-font !important;
}

.font-secondary{
  font-family: $secondary-font !important;
}

.font-weight-medium{
  font-weight: 500 !important;
}

.breadcrumb{
  background-color: transparent;
  justify-content: center;
  &-item{
    font-family: $primary-font;
    letter-spacing: 2px;
    font-size: 10px;
    font-weight: 600;
    a{
      color: $primary-color;
    }
  }
}

/* overlay */

.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .5;
  }
}

.bg-primary {
  background: $primary-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-default {
  color: $text-color !important;
}

.bg-dark{
  background-color: $dark !important;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.rounded {
  border-radius: 6px !important;
}

@media screen and (min-width:991px) and (max-width:1200px) {
  .no-gutters-lg {
    margin-right: 0;
    margin-left: 0;
    >.col, >[class*=col-] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.mark, mark {
  padding: 0;
}