.article-card {
  padding: 30px;
  border-radius: 0;
  box-shadow: 0 5px 25px rgba($black,.05);
  &-sm {
    @include desktop-lg {
      padding: 20px;
    }
  }
  @include desktop {
    padding: 25px;
  }
  @include mobile {
    padding: 15px;
  }
}
.card {
  border: 0;
  position: relative;

  &-meta {
    padding-left: 0;

    li {
      list-style-type: none;
      margin-right: 10px;
      display: inline-block;

      a {
        font-weight: 500;
        color: $text-color-light;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  &-footer {
    background-color: transparent;

    span {
      vertical-align: middle;
      color: $text-color-light;
    }
  }
  &-img{
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}
.card-image {
  position: relative;
  img {
    border-radius: 0;
  }
  .post-info {
    position: absolute;
    right: 0;
    top: -5px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    span {
      display: inline-block;
      line-height: 1.3;
      font-size: 12px;
      padding: 3px 8px;
      padding-top: 4px;
      background-color: rgba($black, .6);
      backdrop-filter: blur(10px);
      color: $white;
      border-radius: 0;
      margin-top: 5px;
    }
  }
}

.read-more-btn {
  font-family: $secondary-font;
  font-size: 18px;
}

.post-title {
  color: $black;
	display: inline;
  text-decoration: none;
  background-image: linear-gradient(lighten(saturate($primary_color, 0.15), 20.00), lighten(saturate($primary_color, 0.15), 20.00));
  background-position: 0% 80%;
  background-repeat: no-repeat;
  background-size: 0% 8px;
  transition: background-size .3s;
  &-sm {
    background-size: 0% 3px;
  }
}

a.post-title:hover {
  color: $black;
  background-size: 100% 8px;
}
a.post-title-sm:hover {
  background-size: 100% 3px;
}

/* blog */
.pagination {
  justify-content: center;

  .page-item {
    .page-link {
      display: inline-block;
      width: 42px;
      height: 42px;
      border-radius: 0;
      border: 1px solid $border-color;
      text-align: center;
      margin: 0 4px;
      font-weight: 500;
      color: $dark;
      padding: 0;
      line-height: 41px;
      box-shadow: 0 5px 25px rgba($black,.05);

      &:focus,
      &:hover {
        box-shadow: none;
        background: $primary-color;
        color: $white;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        border-radius: 0;
      }
    }

    &.active {
      .page-link {
        background: $primary-color;
        color: $white;
        border-color: $primary-color;
      }
    }
  }
}

// post meta
.post-meta {
  padding-left: 0;

  li {
    list-style-type: none;
    display: inline-block;
    color: $text-color-light;
    font-weight: 500;
    a {
      display: inline-block;
      background-color: rgba($dark, .05);
      color: darken($dark, 20);
      text-transform: capitalize;
      padding: 2px 8px;
      margin-bottom: 8px;
      margin-right: 2px;
      font-size: 14px;
      border-radius: 0;
      &:hover {
        background-color: rgba(lighten(saturate($primary_color, 0.15), 20.00), .25);
      }
    }

    svg {
      display: inline-block;
    }
  }
}


.image-fallback {
  font-size: 0;
  height: 220px;
  width: 100%;
  background-color: darken($body-color, 5);
  text-align: center;
  border-radius: 0;
  display: inline-block;
  &::first-letter {
    font-size: 80px;
    line-height: 220px;
    font-weight: bold;
    color: $text-color-dark;
  }
  &.post-thumb-sm {
    &::first-letter {
      font-size: 50px;
      line-height: 60px;
    }
  }
  &-sm {
    height: 120px;
    width: 120px;
    margin-bottom: 20px;
    &::first-letter {
      font-size: 50px;
      line-height: 120px;
    }
  }
  &-xs {
    height: 80px;
    width: 80px;
    &::first-letter {
      font-size: 38px;
      line-height: 80px;
    }
  }
}

// breadcrumb
.breadcrumbs {
  background-color: transparent;
  padding: 15px 0;

  a {
    color: $dark;
    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      color: rgba($dark, .5);
      pointer-events: none;
    }
  }
}

/* content style */
.content {
  * {
    margin-bottom: 5px;
  }
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  a {
    color: inherit !important;
    display: inline;
    text-decoration: none;
    background-image: linear-gradient(lighten(saturate($primary_color, 0.15), 20.00), lighten(saturate($primary_color, 0.15), 20.00));
    background-position: 0% 110%;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    transition: .3s;
    &:hover {
      color: $black;
      background-size: 100% 100%;
    }
  }
  &.bg-dark {
    a:hover {
      background-image: linear-gradient(rgba($primary_color, .4), rgba($primary_color, .4));
    }
  }

  ol {
    padding-left: 20px;
  }

  ul {
    padding-left: 0;

    li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
      list-style-type: none;

      a {
        display: inline-block;
        margin-bottom: 10px;
      }

      &::before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        border-radius: 100%;
        left: 0;
        top: 7px;
        background-color: $primary-color;
        transition: .3s ease;
      }
    }
  }

  table {
    text-align: left;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;

    th,
    td {
      padding: .75rem;
      vertical-align: top;
      border: 1px solid #dee2e6
    }

    thead {
      background: darken($color: $light, $amount: 2);
    }

    tbody {
      background: $light;

      td {
        text-align: left !important;
      }
    }
  }


  blockquote {
    border-left: 4px solid $primary-color;
    padding: 20px;
    margin: 20px 0;

    p {
      margin-bottom: 0;
      color: $text-color;
      font-style: italic;
      font-size: 20px;
      font-weight: 500;
    }
  }

  pre {
    padding: 10px 20px;
    background: $light;
  }
}

/* widget */
.widget-blocks {
  padding-left: 20px;
  @include desktop {
    padding-left: 0;
  }
  .row {
    >.col:last-child, >[class*=col-]:last-child {
      .widget {
        margin-bottom: 0 !important;
      }
    }
  }
}
.widget {
  margin-bottom: 50px;
  &-body {
    padding: 30px;
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 5px 25px rgba($black,.05);
    &-sm {
      @include desktop-lg {
        padding: 20px;
      }
    }
    @include desktop {
      padding: 25px;
    }
    @include mobile {
      padding: 15px;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    .card {
      border-bottom: 1px dashed $border-color;
      padding-bottom: 15px;
    }
    .media {
      img {
        height: 80px;
        width: 80px !important;
        object-fit: cover;
        border-radius: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
      &:hover {
        opacity: .8;
      }
    }
    li {
      display: inline-block;
      a {
        background-color: rgba($dark, .05);
        color: darken($dark, 20);
        text-transform: capitalize;
        padding: 6px 16px;
        margin-bottom: 9px;
        margin-right: 9px;
        display: block;
        font-size: 14px;
        border-radius: 0;
        &:hover {
          background-color: rgba(lighten(saturate($primary_color, 0.15), 20.00), .25);
        }
      }
    }
  }
}

.author-thumb-sm {
  height: 150px;
  object-fit: cover;
  @include desktop {
    height: 250px;
  }
  @include tablet {
    height: 200px;
  }
}

.post-thumb-sm {
  height: 80px;
  width: 100px;
  object-fit: cover;
  border-radius: 0;
}

.copyright {
  background-color: darken($dark, 2) !important;
  color: #e8e8e8;
  padding: 20px 0;
  font-size: 14px;
  text-align: center;
}

footer {
  .section {
    padding: 45px 0;
  }
}
.navbar-footer {
  .nav-link:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

.form-control {
  border-radius: 0;
  min-height: 50px;
  padding: 10px 15px;
  &:focus {
    border-color: $dark;
    box-shadow: none;
  }
}