/*  typography */

body {
  line-height: 1.6;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: $text-color;
}

p {
  font-weight: 400;
  color: $text-color;
  font-size: 16px;
  line-height: 1.6;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  color: $dark;
  font-family: $secondary-font;
  font-weight: 700;
  line-height: 1.3;
}

h1, .h1{
  font-size: 36px;
  @include desktop {
    font-size: 30px;
  }
  @include tablet {
    font-size: 26px;
  }
}

h2, .h2{
  font-size: 28px;
  @include tablet {
    font-size: 24px;
  }
}

h3, .h3{
  font-size: 22px;
  @include tablet {
    font-size: 20px;
  }
}

h4, .h4{
  font-size: 20px;
}

h5, .h5{
  font-size: 16px;
}

h6, .h6{
  font-size: 14px;
}
